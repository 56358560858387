import {call, put, select, takeLatest} from 'redux-saga/effects';
import {approvalStateActions, fetchApprovalCustomerListDataCreatorTypeName, fetchApprovalDataCreatorTypeName} from './slice';
import {API_PATH, TOAST_MESSAGES} from '@app/common/constants';
import {NetworkService} from '@app/services';
import {showEngagementErrors, successToast} from '../../setup-engagement-module/setup-engagement-screens/engagementConfig';
import {approvalStateSelector} from './selectors';

/**
 * fetch ApprovalData Watcher
 */
export function* fetchApprovalDataWatcher() {
  yield takeLatest(
    fetchApprovalDataCreatorTypeName,
    fetchApprovalDataWatcherWorker,
  );
}

/**
 * fetch ApprovalData Worker
 */
export function* fetchApprovalDataWatcherWorker(action: any): any {
  try {
    if (
      action &&
      action.payload &&
      Object.keys(action.payload).length > 0 &&
      typeof action.payload.url !== 'undefined'
    ) {
      if (
        action.payload.url === 'approvals/category' &&
        typeof action.payload.staffPositionId !== 'undefined'
      ) {
        const url = `${API_PATH.setupEngagement.approvalData}/${action.payload.url}?isMRShow=false&staffPositionId=${action.payload.staffPositionId}`;
        const categoryData = yield call(NetworkService.get, url, {}, {});
        if (categoryData.status === 200) {
          yield put(approvalStateActions.setLoaderData({isLoading: false}));
          yield put(
            approvalStateActions.setApprovalData({
              categoryData: categoryData?.data,
            }),
          );
        } else {
          yield put(approvalStateActions.setLoaderData({isLoading: false}));
          yield put(
            approvalStateActions.setApprovalData({
              categoryData: [],
            }),
          );
          showEngagementErrors(categoryData?.data?.error);
        }
      } else if (
        action.payload.url === 'approvals/speciality' &&
        typeof action.payload.staffPositionId !== 'undefined'
      ) {
        const url = `${API_PATH.setupEngagement.approvalData}/${action.payload.url}?isMRShow=false&staffPositionId=${action.payload.staffPositionId}`;
        const specialityData = yield call(NetworkService.get, url, {}, {});
        if (specialityData.status === 200) {
          yield put(approvalStateActions.setLoaderData({isLoading: false}));
          yield put(
            approvalStateActions.setApprovalData({
              specialityData: specialityData?.data,
            }),
          );
        } else {
          yield put(approvalStateActions.setLoaderData({isLoading: false}));
          yield put(
            approvalStateActions.setApprovalData({
              specialityData: [],
            }),
          );
          showEngagementErrors(specialityData?.data?.error);
        }
      } else if (
        action.payload.url === 'approvals/showlist' &&
        typeof action.payload.engagementId !== 'undefined' &&
        typeof action.payload.staffpositionId !== 'undefined'
      ) {
        const url = `${API_PATH.setupEngagement.approvalData}/${action.payload.url}?engagementId=${action.payload.engagementId}&staffpositionId=${action.payload.staffpositionId}`;
        const showListData = yield call(NetworkService.get, url, {}, {});
        if (showListData.status === 200) {
          yield put(approvalStateActions.setLoaderData({isLoading: false}));
          yield put(
            approvalStateActions.setApprovalData({
              showListData: showListData?.data,
              showListResponse: showListData?.data,
            }),
          );
        } else {
          yield put(approvalStateActions.setLoaderData({isLoading: false}));
          yield put(
            approvalStateActions.setApprovalData({
              showListData: [],
              showListResponse: [],
            }),
          );
          showEngagementErrors(showListData?.data?.error);
        }
      } else if (
        action.payload.url === 'approvals/history' &&
        typeof action.payload.engagementId !== 'undefined' &&
        typeof action.payload.staffpositionId !== 'undefined'
      ) {
        const url = `${API_PATH.setupEngagement.approvalData}/${action.payload.url}?engagementId=${action.payload.engagementId}&staffpositionId=${action.payload.staffpositionId}`;
        const approvalHistoryData = yield call(NetworkService.get, url, {}, {});
        if (approvalHistoryData.status === 200) {
          yield put(approvalStateActions.setLoaderData({isLoading: false}));
          yield put(
            approvalStateActions.setApprovalData({
              approvalHistoryData: approvalHistoryData?.data,
            }),
          );
        } else {
          yield put(approvalStateActions.setLoaderData({isLoading: false}));
          yield put(
            approvalStateActions.setApprovalData({
              approvalHistoryData: [],
            }),
          );
          showEngagementErrors(approvalHistoryData?.data?.error);
        }
      } else if (
        action.payload.url === 'engagementslistbyparty' &&
        typeof action.payload.partyId !== 'undefined'
      ) {
        const url = `${API_PATH.setupEngagement.approvalData}/${action.payload.url}?partyId=${action.payload.partyId}`;
        const engagementsList = yield call(NetworkService.get, url, {}, {});
        if (engagementsList.status === 200) {
          yield put(approvalStateActions.setLoaderData({isLoading: false}));
          yield put(
            approvalStateActions.setApprovalData({
              engagementsList: engagementsList?.data,
            }),
          );
        } else {
          yield put(approvalStateActions.setLoaderData({isLoading: false}));
          yield put(
            approvalStateActions.setApprovalData({
              engagementsList: [],
            }),
          );
          showEngagementErrors(engagementsList?.data?.error);
        }
      }
      else if (
        action.payload.url === 'engagement/wsfacodes' &&
        typeof action.payload.staffPositionIds !== 'undefined'
      ) {
        const url = `${API_PATH.setupEngagement.approvalData}/${action.payload.url}?staffPositionIds=${action.payload.staffPositionIds}`;
        const approvalwsfaCodesData = yield call(NetworkService.get, url, {}, {});
        
        if (approvalwsfaCodesData.status === 200) {
          yield put(approvalStateActions.setLoaderData({isLoading: false}));
          yield put(
            approvalStateActions.setApprovalwsfaCodes(approvalwsfaCodesData?.data),
          );
        } else {
          yield put(approvalStateActions.setLoaderData({isLoading: false}));
          yield put(
            approvalStateActions.setApprovalwsfaCodes([]),
          );
          showEngagementErrors(approvalwsfaCodesData?.data?.error);
        }
      } 
      else if (
        action.payload.url === 'approvals/status' &&
        typeof action.payload.isApprove !== 'undefined'
      ) {
        const approvalStatusData = yield select(approvalStateSelector.getApprovalStatusData())
        const url = `${API_PATH.setupEngagement.approvalData}/${action.payload.url}?isApprove=${action.payload.isApprove}`;
        const responseData = yield call(
          NetworkService.post,
          url,
          approvalStatusData,
        );
        yield put(
          approvalStateActions.setApprovalStatusResponseData(responseData),
        );

        if(action.payload.isApprove){
          successToast(TOAST_MESSAGES.SUCCESSFULLY_ADD_DOCTORS)
        }else{
          successToast(TOAST_MESSAGES.SUCCESSFULLY_REJECT_DOCTORS)
        }

        if (responseData?.status === 200) {
          yield put(approvalStateActions.setApprovalData({isApproved: false}));
          yield put(approvalStateActions.setApprovalCustomerListResponseData({}));
        } else {
          yield put(approvalStateActions.setLoaderData({isLoading: false}));
          showEngagementErrors(responseData?.data?.error);
        }
      } else {
        yield put(approvalStateActions.setLoaderData({isLoading: false}));
        throw new Error();
      }
    }
  } catch (error) {
    yield put(approvalStateActions.setLoaderData({isLoading: false}));
    console.error(error);
  }
}

/**
 * fetch ApprovalCustomerList Watcher
 */
export function* fetchApprovalCustomerListDataWatcher() {
  yield takeLatest(
    fetchApprovalCustomerListDataCreatorTypeName,
    fetchApprovalCustomerListDataWatcherWorker,
  );
}

/**
 * fetch ApprovalCustomerList Worker
 */
export function* fetchApprovalCustomerListDataWatcherWorker(): any {
  try {
    const approvalData = yield select(approvalStateSelector.getApprovalData());
    const url = `${API_PATH.setupEngagement.adoptedCustomerData}`;
    const responseData = yield call(
      NetworkService.post,
      url,
      approvalData.wsfaData,
    );
    if (responseData?.status === 200) {
      yield put(
        approvalStateActions.setApprovalCustomerListResponseData(responseData?.data),
      );
      yield put(
        approvalStateActions.setApprovalData({
          wsfaData: [],
        }),
      );
    } else {
      yield put(approvalStateActions.setLoaderData({isLoading: false}));
      showEngagementErrors("Failed to save the data");
    }
  } catch (error) {
    console.log(error);
  }
}
