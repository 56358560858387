import {createAction, createSlice} from '@reduxjs/toolkit';

export const setupEngagementModuleInitialState = {
  engagementData: [],
  divisionsData: [],
  focusBrandsData: [],
  stateSelectionData: [],
  responsibilitiesData: [],
  filteredFocusBrandsData: [],
  selectedDoctorsData: [],
  assignDoctorsMrData: [],
  assignManagersMrData: [],
  managerSelectionData: [],
  segmentationData: [],
  base64Data:[],
  loaderData: {
    isLoading: false,
    mssg: '',
  },
  engagementInfoLoaderData: {
    engagementInfoIsLoading: false,
    mssg: '',
  },
  lastYearHighlightsData: {
    lastYearHighlightsConstraintsData: [],
    lastYearHighlightsDoctorsList: [],
    regionwiseStateHeadList: [],
    lastYearHighlightsManagersList: [],
    doctorsListData: [],
  },
  managersListParameters: {
    url: 'lastyearhighlights/managerlist',
    divisionId: 0,
    staffPositionId: 0,
  },
  postEngagementData: {
    setupIsValid: {
      isValid: false,
      message: ''
    },
    engagementName: '',
    engagementType: [],
    requiredEngagementTypes: [],
    priority: [],
    updatedFocusBrandsData: [],
    subCoverage: [],
    engagementcoveragesType: [],
    engagementCountries: [],
    engagementGeographyCoverage: [],
    geographyCoverageSelection: [],
    managerCoverageSelection: [],
    listOfManagerSelection: [],
    updatedGeographyData: [],
    updatedManagerData: [],
    isIncompleteEng: false,
    selectedCoverageData: {
      geography: [],
      manager: []
    },
    engagementconstraintqualifications: [],
    engagementconstraintpractices: [],
    engagementconstraintsdoccategories: [],
    engagementconstraints3x3buckets: [],
    constraintTableValue: null,
    constraintIsEnableValue: false,
    doctorListTableDivisionValue: null,
    doctorListIsEnableValue: false,
    doctorsSelectionTableAreaValue: null,
    doctorsSelectionTableSpecialtyValue: null,
    doctorsSelectionTableCategoryValue: null,
    regionWiseTableDivisionValue: null,
    regionWiseTableStateHeadValue: null,
    regionwiseIsEnableValue: false,
    viewListTableMRValue: null,
    assignResponsibilitiesAssignValue: null,
    invitationFiles: [],
    literatureFiles: [],
    participationDates: [],
    participationDurationStartValue: [],
    participationDurationEndValue: [],
    segmentationSelect: [],
    constraintsToggleState: [],
    focusBrandsToggleState: [],
    highlightsToggleState: 'Region wise',
    assignDoctorsToggleValue: true,
    basicInformationDateType: 'Date',
    managerCoverageRoles: [],
    brandsFilterData: [],
    showLoader:false,
    loadMoreIsEnable: false,
    inProgressLoadMore: false,
    isDurationValid:false,
    rejectionReason: null,
    completedEngagementApplyIsDisable: true,
    inProgressEngagementApplyIsDisable: true,
    completedEngagementsIsDisable: false,
    engagementIsEdit: false,
    userDivisions: []
  },

  postBasicInformationData: {
    name: '',
    typeId: 0,
    fromDate: null,
    toDate: null,
    isPriority: null,
    setupDone: false,
    frequencyId: 0,
    frequencyStartDate: null,
    frequencyEndDate: null,
    isDocumentReadOnly: true,
  },
  postDivisionsData: [],
  postFocusBrandsData: [],
  postCoverageData: {
    engagementId: 0,
    engagementcoverages: [],
    engagementparticipations: [],
  },
  postConstraintsData: {
    id: 0,
    engagementconstraintqualifications: [],
    engagementconstraintpractices: [],
    engagementconstraintsdoccategories: [],
    engagementconstraints3x3buckets: [],
  },
  postEnrollmentConfigsData: [],
  postResponsibilitiesData: [],
  postResponseData: {
    basicInfoResponseData: {},
    divisionsResponseData: {},
    focusBrandsResponseData: {},
    coveragesResponseData: {},
    constraintsResponseData: {},
    enrollmentConfigResponseData: {},
    assignResponsibilitiesResponseData: {},
    fileUploadResponseData: {},
    coveragesResponse: {},
    updatedEngagementResponse: {}
  },
  responseData: {
    numberOfEligibleDoctors: {},
    listOfEligibleDoctors: {},
    doctorsData: {},
    removeCoverageSelectionResponse: {},
    updatedEngagementResponse: {},
  },
  postTemplateData: {
    postInvitationData: [],
    postLiteratureData: [],
  },
  eligibleDoctorsConstraintsData: {
    engagementfocusdivisions: [],
    engagementconstraintqualifications: [],
    engagementconstraintpractices: [],
    engagementconstraintsdoccategories: [],
  },
  doctorsMrData: {geoTypeId: 0, selections: 0, divisions: 0},
  managersMrData: {selections: 0, divisions: 0, roleId: 0, engagementId: 0},
  removeCoverageSelectionData: {},
  inCompleteEngagementsData: [],
  completedEngagementsData: [],
  inProgressEngagementsData: [],
  inCompleteEngagementInfo: {},
  inCompleteEngagementConstraintsData: {},
  completedEngagementsFilterData: {
    pageNo: 1,
    pageSize: 10,
    year: null,
    search: ''
  },
  inProgressEngagementsFilterData: {
    pageNo: 1,
    pageSize: 10,
    year: null,
    search: ''
  },
  rejectionDropdownData: []
};

export const fetchSetupEngagementModuleCreator = createAction<any>(
  'SETUP_ENGAGEMENT_MODULE/FETCH_ENGAGEMENT_DATA',
);
export const fetchSetupEngagementModuleCreatorTypeName =
  fetchSetupEngagementModuleCreator(null).type;

export const fetchFocusBrandsCreator = createAction<any>(
  'SETUP_ENGAGEMENT_MODULE/FETCH_FOCUS_BRANDS_DATA',
);
export const fetchFocusBrandsCreatorTypeName =
  fetchFocusBrandsCreator(null).type;

export const fetchSetupEngagementModuleDivisionsCreator = createAction<any>(
  'SETUP_ENGAGEMENT_MODULE/FETCH_DIVISIONS_DATA',
);
export const fetchSetupEngagementModuleDivisionsCreatorTypeName =
  fetchSetupEngagementModuleDivisionsCreator(null).type;

export const fetchSetupEngagementModuleStateSelectionCreator =
  createAction<any>('SETUP_ENGAGEMENT_MODULE/FETCH_STATE_SELECTION_DATA');
export const fetchSetupEngagementModuleStateSelectionCreatorTypeName =
  fetchSetupEngagementModuleStateSelectionCreator(null).type;

export const fetchSetupEngagementManagerSelectionCreator = createAction<any>(
  'SETUP_ENGAGEMENT_MODULE/FETCH_MANAGER_SELECTION_DATA',
);
export const fetchSetupEngagementManagerSelectionCreatorTypeName =
  fetchSetupEngagementManagerSelectionCreator(null).type;

export const fetchSetupEngagementResponsibilitiesCreator = createAction<any>(
  'SETUP_ENGAGEMENT_MODULE/RESPONSIBILITIES',
);
export const fetchSetupEngagementResponsibilitiesCreatorTypeName =
  fetchSetupEngagementResponsibilitiesCreator(null).type;

export const fetchSetupEngagementModuleMrDataCreator = createAction<any>(
  'SETUP_ENGAGEMENT_MODULE/MR_DATA',
);
export const fetchSetupEngagementModuleMrDataCreatorTypeName =
  fetchSetupEngagementModuleMrDataCreator(null).type;

//  Manager MR data
export const fetchSetupEngagementModuleManagerMrDataCreator = createAction<any>(
  'SETUP_ENGAGEMENT_MODULE/MANAGER_MR_DATA',
);
export const fetchSetupEngagementModuleManagerMrDataCreatorTypeName =
  fetchSetupEngagementModuleManagerMrDataCreator(null).type;

//lastYear highlights GET API

export const fetchLastYearHighlightsDataCreator = createAction<any>(
  'SETUP_ENGAGEMENT_MODULE/LAST_YEAR_HIGHLIGHTS',
);
export const fetchLastYearHighlightsDataCreatorTypeName =
  fetchLastYearHighlightsDataCreator(null).type;

//3x3segmentation GET API
export const fetch3x3SegmentationDataCreator = createAction<any>(
  'SETUP_ENGAGEMENT_MODULE/3X3_SEGMENTATION',
);
export const fetch3x3SegmentationDataCreatorTypeName =
  fetch3x3SegmentationDataCreator(null).type;

// Post API
export const postSetupEngagementDataCreator = createAction<any>(
  'SETUP_ENGAGEMENT_MODULE/POST_ENGAGEMENT_DATA',
);
export const postSetupEngagementDataCreatorTypeName =
  postSetupEngagementDataCreator(null).type;

// post API to get number of eligible doctors
export const postEligibleDoctorsByConstraintsDataCreator = createAction<any>(
  'SETUP_ENGAGEMENT_MODULE/POST_DOCTORS_CONSTRAINTS_DATA',
);
export const postEligibleDoctorsByConstraintsDataCreatorTypeName =
  postEligibleDoctorsByConstraintsDataCreator(null).type;

// Delete API for removal of coverage selection
export const removeCoverageSelectionDataCreator = createAction<any>(
  'SETUP_ENGAGEMENT_MODULE/REMOVE_COVERAGE_SELECTION_DATA',
);
export const removeCoverageSelectionDataCreatorTypeName =
  removeCoverageSelectionDataCreator(null).type;

//inComplete Engagements
export const inCompleteEngagementsDataCreator = createAction<any>(
  'SETUP_ENGAGEMENT_MODULE/INCOMPLETE_ENGAGEMENTS_DATA',
);
export const inCompleteEngagementsDataCreatorTypeName =
   inCompleteEngagementsDataCreator(null).type;

//Engagements Constraints data
export const inCompleteEngagementsConstraintsDataCreator = createAction<any>(
  'SETUP_ENGAGEMENT_MODULE/INCOMPLETE_ENGAGEMENTS_CONSTRAINTS_DATA',
);
export const inCompleteEngagementsConstraintsDataCreatorTypeName =
  inCompleteEngagementsConstraintsDataCreator(null).type;

//completed Engagements
export const completedEngagementsDataCreator = createAction<any>(
  'SETUP_ENGAGEMENT_MODULE/COMPLETED_ENGAGEMENTS_DATA',
);
export const completedEngagementsDataCreatorTypeName =
   completedEngagementsDataCreator(null).type;

//inProgress Engagements
export const inProgressEngagementsDataCreator = createAction<any>(
  'SETUP_ENGAGEMENT_MODULE/INPROGRESS_ENGAGEMENTS_DATA',
);
export const inProgressEngagementsDataCreatorTypeName =
  inProgressEngagementsDataCreator(null).type;

//base64String data
export const fetchBase64DataCreator = createAction<any>(
  'SETUP_ENGAGEMENT_MODULE/BASE64_STRING_DATA',
);
export const fetchBase64DataCreatorTypeName =
    fetchBase64DataCreator(null).type;


const setupEngagementModuleStateHandler: any = createSlice({
  name: 'SETUP_ENGAGEMENT_MODULE_HANDLER',
  initialState: setupEngagementModuleInitialState,
  reducers: {
    setEngagementData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        engagementData: payload,
      };
    },
    setDivisionData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        divisionsData: payload,
      };
    },
    setFocusBrandsData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        focusBrandsData: payload,
      };
    },
    setFilteredFocusBrandsData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        filteredFocusBrandsData: payload,
      };
    },
    setStateSelectionData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        stateSelectionData: payload,
      };
    },
    setManagerSelectionData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        managerSelectionData: payload,
      };
    },
    setSegmentationData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        segmentationData: payload,
      };
    },
    setResponsibilitiesData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        responsibilitiesData: payload,
      };
    },
    setSelectedDoctorsData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        selectedDoctorsData: payload,
      };
    },
    setLoaderData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        loaderData: payload,
      };
    },
    setEngagementInfoLoaderData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        engagementInfoLoaderData: payload,
      };
    },
    setPostEngagementData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        postEngagementData: {...prevState.postEngagementData, ...payload},
      };
    },
    setPostBasicInformationData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        postBasicInformationData: {
          ...prevState.postBasicInformationData,
          ...payload,
        },
      };
    },
    setPostDivisionsData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        postDivisionsData: payload,
      };
    },
    setPostFocusBrandsData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        postFocusBrandsData: payload,
      };
    },
    setPostCoverageData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        postCoverageData: {...prevState.postCoverageData, ...payload},
      };
    },
    setPostConstraintsData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        postConstraintsData: {...prevState.postConstraintsData, ...payload},
      };
    },
    setPostEnrollmentConfigsData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        postEnrollmentConfigsData: payload,
      };
    },
    setPostResponsibilitiesData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        postResponsibilitiesData: payload,
      };
    },
    setPostResponseData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        postResponseData: {...prevState.postResponseData, ...payload},
      };
    },
    setResponseData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        responseData: {...prevState.responseData, ...payload},
      };
    },
    setPostTemplateData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        postTemplateData: payload,
      };
    },
    setEligibleDoctorsConstraintsData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        eligibleDoctorsConstraintsData: {
          ...prevState.eligibleDoctorsConstraintsData,
          ...payload,
        },
      };
    },
    setAssignDoctorsMrData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        assignDoctorsMrData: payload,
      };
    },
    setAssignManagersMrData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        assignManagersMrData: payload,
      };
    },
    setDoctorsMrData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        doctorsMrData: {...prevState.doctorsMrData, ...payload},
      };
    },
    setManagersMrData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        managersMrData: {...prevState.managersMrData, ...payload},
      };
    },
    setRemoveCoverageSelectionData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        removeCoverageSelectionData: payload,
      };
    },
    setLastYearHighlightsData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        lastYearHighlightsData: {
          ...prevState.lastYearHighlightsData,
          ...payload,
        },
      };
    },
    setManagersListParameters: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        managersListParameters: {
          ...prevState.managersListParameters,
          ...payload,
        },
      };
    },
    setInCompleteEngagementsData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        inCompleteEngagementsData: payload,
      };
    },
    setCompletedEngagementsData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        completedEngagementsData: payload,
      };
    },
    setInProgressEngagementsData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        inProgressEngagementsData: payload,
      };
    },
    setCompletedEngagementsFilterData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        completedEngagementsFilterData: {
          ...prevState.completedEngagementsFilterData,
          ...payload,
        },
      };
    },
    setInProgressEngagementsFilterData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        inProgressEngagementsFilterData: {
          ...prevState.inProgressEngagementsFilterData,
          ...payload,
        },
      };
    },
    setInCompleteEngagementInfo: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        inCompleteEngagementInfo: {
          ...prevState.inCompleteEngagementInfo,
          ...payload,
        },
      };
    },
    setInCompleteEngagementConstraintsData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        inCompleteEngagementConstraintsData: {
          ...prevState.inCompleteEngagementConstraintsData,
          ...payload,
        },
      };
    },
    setRejectionDropdownData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        rejectionDropdownData: payload,
      };
    },
    setBase64Data: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        base64Data: payload,
      };
    },
    resetSetupEngagementModule: state => {
      return setupEngagementModuleInitialState;
    },
  },
});

export const setupEngagementModuleStateActions =
  setupEngagementModuleStateHandler.actions;
export const setupEngagementModuleStateReducer =
  setupEngagementModuleStateHandler.reducer;
